import React from 'react'

import {
  FormGroup,
  Help,
  Input,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Lomakekentän otsikointi">
    <Section title="Periaatteet">
      <List variant="unordered">
        <ListItem>
          Lomakekentälle tulee aina määritellä vähintään otsikko (
          <Code>label</Code>).
        </ListItem>
        <ListItem>
          Muita otsikkoelementtejä (<Code>subLabel</Code>, <Code>help</Code>) ei
          tule käyttää ilman otsikkoa.
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne">
      <SectionHeading>
        <Code>label</Code>
      </SectionHeading>
      <Paragraph>
        Lyhyesti ja ytimekkäästi ilmaistu otsikko, mieluiten mahtuvaksi yhdelle
        riville lomakkeen täydellä leveydellä. Otsikon lopussa ei käytetä
        kaksoispistettä. Harvinaisemmissa tapauksissa näkyvä otsikko on
        mahdollista jättää pois, mutta tällöin otsikko on tarjottava
        ARIA-attribuutilla.
      </Paragraph>
      <Help>
        <Paragraph noMargin>
          Style Guiden lomakekomponentit yhdistävät otsikon lomakekenttään
          automaattisesti. Muissa tapauksissa lomakekenttä on yhdistettävä
          otsikkoon määrittelemällä <Code>htmlFor</Code>, sekä lomakekentälle
          vastaava <Code>id</Code>.
        </Paragraph>
      </Help>
      <SectionHeading>
        <Code>subLabel</Code>
      </SectionHeading>
      <Paragraph>
        Lomakekentän otsikkoa voidaan täydentää määrittelemällä alaotsikko, joka
        asettuu otsikon alapuolelle. Alaotsikossa on tarkoitus ilmaista
        välttämättömin ohjeistus, mutta se on myös hyvä pitää lyhyenä ja
        ytimekkäänä.
      </Paragraph>
      <Help>
        <Paragraph noMargin>
          Style Guiden lomakekomponentit yhdistävät alaotsikon lomakekenttään
          automaattisesti. Muissa tapauksissa lomakekenttä on yhdistettävä
          alaotsikko määrittelemällä <Code>subLabelId</Code>, sekä
          lomakekentälle vastaava <Code>ariaDescribedby</Code>.
        </Paragraph>
      </Help>
      <SectionHeading>
        <Code>required</Code>
      </SectionHeading>
      <Paragraph>Lomakekenttä on pakollinen.</Paragraph>
      <SectionHeading>
        <Code>help</Code>
      </SectionHeading>
      <Paragraph>
        Lomakekentän ei-kriittinen lisäohjeistus piilotetaan info-painikkeen
        taakse. Lisäohjeistus tulee painiketta painamalla otsikon ja alaotsikon
        väliin.
      </Paragraph>
      <SectionHeading>
        <Code>placeholder</Code>
      </SectionHeading>
      <Paragraph>
        Placeholder tekstiä voi käyttää ottaen huomioon, että teksti häviää
        kenttää täyttäessä. Tekstissä ei siksi ole hyvä käyttää tärkeää,
        muistettavaa asiaa.
      </Paragraph>
      <Paragraph>
        Placeholderissa ei pidä toistaa samaa sisältöä, joka on jo annettu
        sublabelissa. Esimerkiksi aika- tai päivämääräformaatti ohjeistetaan
        ensisijasesti vain sublabelissa.
      </Paragraph>
      <SectionHeading>
        <Code>error</Code>
      </SectionHeading>
      <Paragraph>
        Lomakekentän alapuoli on varattu virheviestille. Viestin lopussa ei
        käytetä pistettä, ellei se koostu useammasta lauseesta.
      </Paragraph>
      <Help>
        <Paragraph noMargin>
          Style Guiden lomakekomponentit yhdistävät virheviestin lomakekenttään
          automaattisesti. Muissa tapauksissa lomakekenttä on yhdistettävä
          virheviestiin määrittelemällä <Code>id</Code>, sekä lomakekentälle
          vastaava <Code>ariaDescribedby</Code>.
        </Paragraph>
      </Help>
    </Section>
    <Section title="Esimerkkejä">
      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup noMargin>
          <Input
            error="Error"
            help="Help"
            id="label-example-1"
            label="Label"
            placeholder="Placeholder"
            required
            subLabel="Sub Label"
          />
        </FormGroup>
      </Playground>
    </Section>
  </Content>
)

export default Page
